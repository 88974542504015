import { useEffect, useState } from "react";

import BackIconForm from "../../../assets/svgs/backArrow.svg";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const TabPublishedWorks = ({ publishedWorks }) => {
  
  const [pwBookPublishing, setPwBookPublishing] = useState();
  const [pwAdvertising, setPwAdvertising] = useState();
  const [pwEditorial, setPwEditorial] = useState();
  const [pwMedical, setPwMedical] = useState();
  const [pwEntertaiment, setPwEntertaiment] = useState();
  const [pwOther, setPwOther] = useState();
  const [subPictureBook, setSubPictureBook] = useState();
  const [subMiddleGrade, setSubMiddleGrade] = useState();
  const [subYoungAdult, setSubYoungAdult] = useState();
  const [subGraphicNovel, setSubGraphicNovel] = useState();
  const [subBoardBook, setSubBoardBook] = useState();
  const [subAdultPublishing, setSubAdultPublishing] = useState();
  const [subNovelty, setSubNovelty] = useState();
  const [subBoxSet, setSubBoxSet] = useState();
  const [subGame, setSubGame] = useState();
  const [subSelfHelp, setSubSelfHelp] = useState();
  const [pwLevel, setPwLevel] = useState(1);
  const [currentPw, setCurrentPw] = useState();
  const [pwModal, setPwModal] = useState(false);
  const [carrouselIndex, setCarrouselIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { pages } = useParams();
  const { search } = useParams();

  useEffect(() => {
    const filteredPWBookPublishing = publishedWorks
      .filter((pw) => pw.categoryPW === "publishing")
      .reverse();
    const filteredPWAdvertising = publishedWorks
      .filter((pw) => pw.categoryPW === "advertising")
      .reverse();
    const filteredPWEditorial = publishedWorks
      .filter((pw) => pw.categoryPW === "editorial")
      .reverse();
    const filteredPWMedical = publishedWorks
      .filter((pw) => pw.categoryPW === "medical")
      .reverse();
    const filteredPWEntertaiment = publishedWorks
      .filter((pw) => pw.categoryPW === "entertainment")
      .reverse();
    const filteredPWOther = publishedWorks
      .filter((pw) => pw.categoryPW === "other" || pw.categoryPW === "")
      .reverse();
    const filteredPWSubPictureBook = publishedWorks
      .filter((pw) => pw.subCategoryPW === "pictureBook")
      .reverse();
    const filteredPWSubMiddleGrade = publishedWorks
      .filter((pw) => pw.subCategoryPW === "middleGrade")
      .reverse();
    const filteredPWSubYoungAdult = publishedWorks
      .filter((pw) => pw.subCategoryPW === "youngAdult")
      .reverse();
    const filteredPWSubGraphicNovel = publishedWorks
      .filter((pw) => pw.subCategoryPW === "graphicNovel")
      .reverse();
    const filteredPWSubBoardBook = publishedWorks
      .filter((pw) => pw.subCategoryPW === "boardBook")
      .reverse();
    const filteredPWSubAdultPublishing = publishedWorks
      .filter((pw) => pw.subCategoryPW === "adultPublishing")
      .reverse();
    const filteredPWSubNovelty = publishedWorks
      .filter((pw) => pw.subCategoryPW === "novelty")
      .reverse();
    const filteredPWSubBoxSet = publishedWorks
      .filter((pw) => pw.subCategoryPW === "boxSet")
      .reverse();
    const filteredPWSubGame = publishedWorks
      .filter((pw) => pw.subCategoryPW === "game")
      .reverse();
    const filteredPWSubSelfHelp = publishedWorks
      .filter((pw) => pw.subCategoryPW === "selfHelp")
      .reverse();

    setPwBookPublishing(filteredPWBookPublishing);
    setPwAdvertising(filteredPWAdvertising);
    setPwEditorial(filteredPWEditorial);
    setPwMedical(filteredPWMedical);
    setPwEntertaiment(filteredPWEntertaiment);
    setPwOther(filteredPWOther);
    setSubPictureBook(filteredPWSubPictureBook);
    setSubMiddleGrade(filteredPWSubMiddleGrade);
    setSubYoungAdult(filteredPWSubYoungAdult);
    setSubGraphicNovel(filteredPWSubGraphicNovel);
    setSubBoardBook(filteredPWSubBoardBook);
    setSubAdultPublishing(filteredPWSubAdultPublishing);
    setSubNovelty(filteredPWSubNovelty);
    setSubBoxSet(filteredPWSubBoxSet);
    setSubGame(filteredPWSubGame);
    setSubSelfHelp(filteredPWSubSelfHelp);
    setLoading(false);
  }, [publishedWorks]);

  const handlePWLevelChange = (pw) => {
    history.push(`/${pages}/${search}?id=${pw.id}`);
    setCurrentPw(pw);
    setPwLevel(2);
  };

  const handleBackButtonPublishedWorks = () => {
    history.push(`/${pages}/${search}?image=0`);
    setPwLevel(1);
  };

  const handleCarousel = (i) => {
    setPwModal(true);
    setCarrouselIndex(i);
  };

  const moveCarrousel = (moveTo) => {
    const newIndex = carrouselIndex + moveTo;
    if (newIndex >= currentPw.pwSecondaryImages.length) {
      setCarrouselIndex(0);
    } else if (newIndex < 0) {
      setCarrouselIndex(currentPw.pwSecondaryImages.length - 1);
    } else {
      setCarrouselIndex(newIndex);
    }
  };

  return (
    <>
      {!loading && (
        <>
          {pwLevel === 1 ? (
            <>
              <div style={{ width: "100%" }}>
                {pwBookPublishing.length !== 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3
                        className="homeh3 pwh3"
                        style={{ marginBottom: "0px" }}
                      >
                        Publishing
                      </h3>
                      <div className="separatorPw"></div>
                    </div>
                    <p style={{ color: "#a9a8a8", marginBottom: "20px" }}>
                      Highlighted Projects currently Available For Review -
                      click an image to see more{" "}
                    </p>
                    <div style={{ paddingLeft: "10%" }}>
                      {subPictureBook.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Picture Book</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subPictureBook.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>

                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subMiddleGrade.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Middle Grade</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subMiddleGrade.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subYoungAdult.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Young Adult</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subYoungAdult.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subGraphicNovel.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Graphic Novel</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subGraphicNovel.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subBoardBook.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Board Book</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subBoardBook.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subAdultPublishing.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Adult Publishing</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subAdultPublishing.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subNovelty.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Novelty</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subNovelty.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subBoxSet.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Box Set</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subBoxSet.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subGame.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Game</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subGame.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                      {subSelfHelp.length !== 0 && (
                        <>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto 1fr",
                            }}
                          >
                            <h3 className="homeh3 pwh3sub">Self-Help</h3>
                          </div>
                          <div className="detail_card2 pwCategpryCont">
                            {subSelfHelp.map((pw, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handlePWLevelChange(pw)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="detail_card6_h">
                                    <img
                                      className="image"
                                      src={pw.pwMainImage}
                                      alt={pw.titlePW}
                                    />
                                  </div>
                                  <label className="pwLabel">
                                    {pw.titlePW}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {pwEditorial.length !== 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3 className="homeh3 pwh3">Editorial</h3>
                      <div className="separatorPw"></div>
                    </div>
                    <div className="detail_card2 pwCategpryCont">
                      {pwEditorial.reverse().map((pw, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => handlePWLevelChange(pw)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="detail_card6_h">
                              <img
                                className="image"
                                src={pw.pwMainImage}
                                alt={pw.titlePW}
                              />
                            </div>
                            <label className="pwLabel">{pw.titlePW}</label>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {pwAdvertising.length !== 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3 className="homeh3 pwh3">Advertising</h3>
                      <div className="separatorPw"></div>
                    </div>
                    <div className="detail_card2 pwCategpryCont">
                      {pwAdvertising.reverse().map((pw, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => handlePWLevelChange(pw)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="detail_card6_h">
                              <img
                                className="image"
                                src={pw.pwMainImage}
                                alt={pw.titlePW}
                              />
                            </div>
                            <label className="pwLabel">{pw.titlePW}</label>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {pwMedical.length !== 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3 className="homeh3 pwh3">Medical</h3>
                      <div className="separatorPw"></div>
                    </div>
                    <div className="detail_card2 pwCategpryCont">
                      {pwMedical.reverse().map((pw, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => handlePWLevelChange(pw)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="detail_card6_h">
                              <img
                                className="image"
                                src={pw.pwMainImage}
                                alt={pw.titlePW}
                              />
                            </div>
                            <label className="pwLabel">{pw.titlePW}</label>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {pwEntertaiment.length !== 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3 className="homeh3 pwh3">Entertainment</h3>
                      <div className="separatorPw"></div>
                    </div>
                    <div className="detail_card2 pwCategpryCont">
                      {pwEntertaiment.reverse().map((pw, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => handlePWLevelChange(pw)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="detail_card6_h">
                              <img
                                className="image"
                                src={pw.pwMainImage}
                                alt={pw.titlePW}
                              />
                            </div>
                            <label className="pwLabel">{pw.titlePW}</label>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                {pwOther.length !== 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3 className="homeh3 pwh3">Other</h3>
                      <div className="separatorPw"></div>
                    </div>
                    <div className="detail_card2 pwCategpryCont">
                      {pwOther.reverse().map((pw, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => handlePWLevelChange(pw)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="detail_card6_h">
                              <img
                                className="image"
                                src={pw.pwMainImage}
                                alt={pw.titlePW}
                              />
                            </div>
                            <label className="pwLabel">{pw.titlePW}</label>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : pwLevel === 2 ? (
            <>
              <div style={{ width: "80%" }}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <img
                    onClick={handleBackButtonPublishedWorks}
                    alt="back"
                    src={BackIconForm}
                    style={{ width: "40px", cursor: "pointer" }}
                  />
                </div>
                {/* INFO DIV */}
                <div
                  style={{
                    display: "grid",
                    marginBottom: "20px",
                    gridTemplateColumns: "0.5fr 1fr",
                    gap: "50px",
                  }}
                >
                  <div>
                    <img src={currentPw.pwMainImage} />
                  </div>
                  <div
                    style={{
                      margin: "15px",
                      display: "grid",
                      gridTemplateColumns: "0.3fr 1fr",
                      height: "fit-content",
                    }}
                  >
                    <h4
                      className="pwh4"
                      style={{
                        fontSize: "1.5rem",
                        marginBottom: "15px",
                        fontWeight: "500",
                      }}
                    >
                      Title:
                    </h4>
                    <h4
                      className="pwh4"
                      style={{ fontWeight: "500", fontSize: "1.5rem" }}
                    >
                      {currentPw.titlePW}
                    </h4>
                    {currentPw.labelAuthorPW !== "" &&
                      currentPw.authorPW !== "" && (
                        <>
                          <h4 className="pwh4">{currentPw.labelAuthorPW}:</h4>
                          <p className="pwP">{currentPw.authorPW}</p>
                        </>
                      )}
                    <h4 className="pwh4">Illustrator:</h4>
                    <p className="pwP">{currentPw.illustratorPW}</p>
                    <h4 className="pwh4">Client:</h4>
                    <p className="pwP">{currentPw.clientPW}</p>
                    <h4 className="pwh4">Published Date:</h4>
                    <p className="pwP">{currentPw.publishedDatePW}</p>
                    <h4 className="pwh4">Work Contributed:</h4>
                    <p className="pwP">{currentPw.artistContributedPW}</p>
                    {currentPw.pwSecondaryImages.length !== 0 && (
                      <>
                        <p
                          style={{
                            color: "#818181",
                            marginBottom: "0px",
                            marginTop: "15px",
                          }}
                        >
                          See inside
                        </p>
                        <div className="pwSecImgCont">
                          {currentPw.pwSecondaryImages.length !== 0 &&
                            currentPw.pwSecondaryImages.map((img, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={() => handleCarousel(i)}
                                  style={{
                                    cursor: "pointer",
                                    backgroundImage: `url(${img})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    height: "14vh",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              );
                            })}
                        </div>
                      </>
                    )}
                    {pwModal ? (
                      <>
                        <div className="modalTutorial">
                          <div className="containerModal">
                            <div className="modalCloseBtn">
                              <button onClick={() => setPwModal(false)}>
                                ×
                              </button>
                            </div>
                            <div
                              style={{
                                maxHeight: "90vh",
                                display: "flex",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <img
                                src="/assets/images/left.png"
                                alt="leftArrow"
                                style={{
                                  width: "6%",
                                  alignSelf: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => moveCarrousel(-1)}
                              />
                              <img
                                src={
                                  currentPw.pwSecondaryImages[carrouselIndex]
                                }
                                alt="secondaryImage"
                                style={{ maxHeight: "90vh" }}
                              />
                              <img
                                src="/assets/images/right.png"
                                alt="rightArrow"
                                style={{
                                  width: "6%",
                                  alignSelf: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => moveCarrousel(1)}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {/* REVIEWS DIV */}
                {currentPw.reviewsPW.length !== 0 && (
                  <div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                      }}
                    >
                      <h3
                        className="homeh3 pwh3"
                        style={{
                          fontSize: "1.5rem",
                          marginBottom: "0px",
                        }}
                      >
                        Reviews
                      </h3>
                      <div className="separatorPw"></div>
                    </div>
                    {currentPw.reviewsPW.map((review, i) => {
                      return (
                        <div style={{ margin: "5px 50px 50px 50px" }}>
                          <div style={{ display: "flex" }}>
                            {review.isStarReview && (
                              <img
                                src="/assets/images/pwStar.svg"
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            <h4 style={{ fontSize: "1.06rem" }}>
                              {" "}
                              {review.descriptiveWords}
                            </h4>
                          </div>
                          <p style={{ color: "#818181" }}>
                            {" "}
                            {review.authorOrSoR}
                          </p>

                          <p></p>
                          <div
                            className="BioDetailDescriptionPortfolio"
                            dangerouslySetInnerHTML={{
                              __html: review.quoteReview,
                            }}
                            style={{ marginBottom: "35px", fontSize: "0.9rem" }}
                          />
                          {review.link !== "" && (
                            <p
                              style={{
                                color: "#D16A27",
                                fontSize: "15px",
                              }}
                            >
                              Source / Site:{" "}
                              <a
                                href={review.link}
                                target="_blank"
                                style={{
                                  color: "#D16A27",
                                  fontSize: "14px",
                                  textDecoration: "underline",
                                }}
                              >
                                Click here
                              </a>
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default TabPublishedWorks;

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ArtistDataAPI } from "../../redux/artistDataSlice";
import {
  getCategoryTypeTwo,
  getArtistCategoryTypeTwo,
} from "../../AxiosFunctions/Axiosfunctionality";
import { sortAlphaOrder } from "../../UserServices/Services";

function Sidebar(props) {
  const { pages } = useParams();
  const { artistDataAPI } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [keywordReducer, setKeywordReducer] = useState([]);
  const [artistData, setArtistData] = useState([]);
  let alpha = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  useEffect(() => {
    if (props.activeBtn === "divisions" || props.activeBtn === "detailedPage") {
      if (artistDataAPI.artistData.length > 0) {
        setArtistData(
          sortAlphaOrder(
            artistDataAPI !== undefined
              ? artistDataAPI.artistData.length > 0
                ? artistDataAPI.artistData
                : []
              : []
          )
        );
      } else {
        dispatch(ArtistDataAPI("kid"));
      }
    }

    return () => {
      if (localStorage.getItem("routePaths")) {
        let route = JSON.parse(localStorage.getItem("routePaths"));
        if (route.find((obj) => obj.artistExist === true)) {
          route.pop();
          // route.push({val:tempData.activeArtist[pages].firstname + " " + tempData.activeArtist[pages].lastname,artistExist:true})
          localStorage.setItem("routePaths", JSON.stringify(route));
        }
      }
    };
  }, [artistDataAPI]);

  useEffect(() => {
    if (props.activeBtn === "EDUCATIONAL") {
      getArtistCategoryTypeTwo({ keyword: "Educational", type: 2 }).then(
        (res) => {
          setArtistData(
            sortAlphaOrder(
              res !== undefined
                ? res.length > 0
                  ? res.filter((e) => e.status === 1)
                  : []
                : []
            )
          );
        }
      );
    } else if (props.activeBtn === "CHARACTER-DEVELOPMENT") {
      getArtistCategoryTypeTwo({
        keyword: "Character Development",
        type: 2,
      }).then((res) => {
        setArtistData(
          sortAlphaOrder(
            res !== undefined
              ? res.length > 0
                ? res.filter((e) => e.status === 1)
                : []
              : []
          )
        );
      });
    } else if (props.activeBtn === "BLACK-AND-WHITE-INTERIOR") {
      getArtistCategoryTypeTwo({
        keyword: "Black and White Interior",
        type: 2,
      }).then((res) => {
        setArtistData(
          sortAlphaOrder(
            res !== undefined
              ? res.length > 0
                ? res.filter((e) => e.status === 1)
                : []
              : []
          )
        );
      });
    } else if (props.activeBtn === "MULTICULTURAL") {
      getArtistCategoryTypeTwo({ keyword: "Multicultural", type: 2 }).then(
        (res) => {
          setArtistData(
            sortAlphaOrder(
              res !== undefined
                ? res.length > 0
                  ? res.filter((e) => e.status === 1)
                  : []
                : []
            )
          );
        }
      );
    } else if (props.activeBtn === "PICTURE-BOOK") {
      getArtistCategoryTypeTwo({ keyword: "Picture Book", type: 2 }).then(
        (res) => {
          setArtistData(
            sortAlphaOrder(
              res !== undefined
                ? res.length > 0
                  ? res.filter((e) => e.status === 1)
                  : []
                : []
            )
          );
        }
      );
    } else if (props.activeBtn === "MIDDLE-GRADE") {
      getArtistCategoryTypeTwo({
        keyword: "COVER: Middle Grade",
        type: 2,
      }).then((res) => {
        setArtistData(
          sortAlphaOrder(
            res !== undefined
              ? res.length > 0
                ? res.filter((e) => e.status === 1)
                : []
              : []
          )
        );
      });
    } else if (props.currentDivision !== "") {
      let searchDivision = "";
      searchDivision =
        props.currentDivision === "Middle Grade"
          ? "COVER: Middle Grade"
          : props.currentDivision;

      getArtistCategoryTypeTwo({ keyword: searchDivision, type: 2 }).then(
        (res) => {
          setArtistData(
            sortAlphaOrder(
              res !== undefined
                ? res.length > 0
                  ? res.filter((e) => e.status === 1)
                  : []
                : []
            )
          );
        }
      );
    }
    getCategoryTypeTwo().then((res) => {
      setKeywordReducer(
        res.sort((a, b) => {
          if (a.keyword > b.keyword) {
            return 1;
          }
          if (a.keyword < b.keyword) {
            return -1;
          }
          return 0;
        })
      );
    });
  }, [props.activeBtn]);

  const mapDivisions = (division) => {
    const divisionsArray = [
      "MIDDLE-GRADE",
      "MIDDLE GRADE",
      "PICTURE-BOOK",
      "PICTURE BOOK",
      "MULTICULTURAL",
      "MULTICULTURAL",
      "BLACK-AND-WHITE-INTERIOR",
      "BLACK AND WHITE \n INTERIOR",
      "CHARACTER-DEVELOPMENT",
      "CHARACTER \n DEVELOPMENT",
      "EDUCATIONAL",
      "EDUCATIONAL",
    ];

    const index = divisionsArray.indexOf(division);

    return index === -1 ? null : divisionsArray[index + 1];
  };
  const routePaths = JSON.parse(localStorage.getItem("routePaths"));
  const linkPath =
    routePaths && routePaths.length > 2 ? routePaths[2]?.link : "/";

  return (
    <div
      id="w-node-_783b3b4a-9daa-ecbf-356e-d84dbb36f4cc-bb36f4cc"
      className="respdivhide"
    >
      <h3
        className="homeh3"
        style={
          props.activeBtn === "detailedPage"
            ? { textDecorationLine: "none" }
            : props.activeBtn === "BLACK-AND-WHITE-INTERIOR"
            ? {
                color: "#000000",
                fontFamily: "Montserrat, sans-serif",
                textUnderlineOffset: "5px",
                fontWeight: 800,
                fontSize: "0.95vw",
                whiteSpace: "pre-line",
                lineHeight: "1.4",
              }
            : {
                color: "#000000",
                fontFamily: "Montserrat, sans-serif",
                textUnderlineOffset: "5px",
                fontWeight: 800,
                whiteSpace: "pre-line",
                lineHeight: "1.4",
              }
        }
      >
        {props.activeBtn === "EDUCATIONAL"
          ? "EDUCATIONAL"
          : props.activeBtn === "CHARACTER-DEVELOPMENT"
          ? "CHARACTER \n DEVELOPMENT"
          : props.activeBtn === "BLACK-AND-WHITE-INTERIOR"
          ? "BLACK AND WHITE \n INTERIOR"
          : props.activeBtn === "MULTICULTURAL"
          ? "MULTICULTURAL"
          : props.activeBtn === "PICTURE-BOOK"
          ? "PICTURE BOOK"
          : props.activeBtn === "MIDDLE-GRADE"
          ? "MIDDLE GRADE"
          : props.activeBtn === "categories"
          ? "CATEGORIES"
          : localStorage.getItem("routePaths") &&
            JSON.parse(localStorage.getItem("routePaths"))[1]?.val ===
              "Categories"
          ? null
          : props.activeBtn === "detailedPage"
          ? "SELECT BY DIVISION"
          : "DIVISIONS"}
      </h3>
      {pages === "categories" ? (
        keywordReducer?.length > 0 ? (
          keywordReducer?.map((item, key) => (
            <div key={key}>
              {item.type === 2 ? (
                <Link
                  to={
                    item.keyword.includes("/")
                      ? "/categories/" + item.keyword.replace(/\//g, "_")
                      : "/categories/" + item.keyword.replace(/\s/g, "_")
                  }
                  onClick={() => {
                    localStorage.setItem(
                      "Category",
                      item.keyword === "3D Rendering"
                        ? "CGI"
                        : item.keyword.charAt(0)?.toUpperCase() +
                            item.keyword.slice(1)
                    );
                  }}
                  className={
                    "divisionslink" +
                    (localStorage.getItem("Category") === item.keyword
                      ? " w--current"
                      : "")
                  }
                >
                  <span className="sidebarlink">
                    {item.keyword?.toUpperCase()}
                    <br />
                  </span>
                </Link>
              ) : (
                ""
              )}
            </div>
          ))
        ) : (
          ""
        )
      ) : (
        <div className="allartist v2">
          {localStorage.getItem("Category") !== "none" &&
          props.currentDivision === "" ? (
            <div>
              <Link to={"/categories"}>
                <span
                  className="homeh3"
                  style={{
                    color: "#000000",
                    fontFamily: "Montserrat, sans-serif",
                    textUnderlineOffset: "5px",
                    fontWeight: 800,
                  }}
                >
                  CATEGORIES
                  <br />
                </span>
              </Link>
              <Link to={linkPath}>
                <span
                  className="homeh3"
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    textUnderlineOffset: "5px",
                    fontWeight: 800,
                  }}
                >
                  {localStorage.getItem("Category")?.toUpperCase()}
                  <br />
                </span>
              </Link>
              <br />
              <br />
            </div>
          ) : (
            <div>
              <Link
                to="/MIDDLE-GRADE"
                className={
                  "divisionslink" +
                  (props.activeBtn === "MIDDLE-GRADE" ||
                  props.currentDivision === "Middle Grade"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">
                  MIDDLE GRADE
                  <br />
                </span>
              </Link>
              <Link
                to="/PICTURE-BOOK"
                className={
                  "divisionslink" +
                  (props.activeBtn === "PICTURE-BOOK" ||
                  props.currentDivision === "Picture Book"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">
                  PICTURE BOOK
                  <br />
                </span>
              </Link>
              <Link
                to="/MULTICULTURAL"
                className={
                  "divisionslink" +
                  (props.activeBtn === "MULTICULTURAL" ||
                  props.currentDivision === "Multicultural"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">MULTICULTURAL</span>
              </Link>
              <span className="sidebarlink">
                <br />
              </span>
              <Link
                to="/BLACK-AND-WHITE-INTERIOR"
                className={
                  "divisionslink" +
                  (props.activeBtn === "BLACK-AND-WHITE-INTERIOR" ||
                  props.currentDivision === "Black and White Interior"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">
                  BLACK AND WHITE INTERIOR
                  <br />
                </span>
              </Link>
              <Link
                to="/CHARACTER-DEVELOPMENT"
                className={
                  "divisionslink" +
                  (props.activeBtn === "CHARACTER-DEVELOPMENT" ||
                  props.currentDivision === "Character Development"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">
                  CHARACTER DEVELOPMENT
                  <br />
                </span>
              </Link>
              <Link
                to="/EDUCATIONAL"
                className={
                  "divisionslink" +
                  (props.activeBtn === "EDUCATIONAL" ||
                  props.currentDivision === "Educational"
                    ? " w--current"
                    : "")
                }
              >
                <span className="sidebarlink">EDUCATIONAL</span>
              </Link>
              <br />
              <br />
              <br />
              <br />
            </div>
          )}

          <h3
            className="homeh3"
            style={{
              textDecorationLine: "none",
              whiteSpace: "pre-line",
              lineHeight: "1.4",
            }}
          >
            {localStorage.getItem("Category") !== "none" &&
            props.currentDivision === ""
              ? localStorage.getItem("Category")?.toUpperCase() + " - ARTISTS"
              : mapDivisions(props.activeBtn)
              ? mapDivisions(props.activeBtn) + " - ARTISTS "
              : props.currentDivision && props.currentDivision !== ""
              ? props.currentDivision?.toUpperCase() + " - ARTISTS"
              : "SELECT BY ARTIST?"}
          </h3>
          {alpha.map((item, key) => (
            <div key={key}>
              {artistData[item] !== undefined ? (
                <div key={key}>
                  {item}
                  <br />
                  {artistData[item].map((item1, key1) => (
                    <div key={key1}>
                      {
                        props.activeBtn === "detailedPage" ?
                        <Link
                          to={"../"+item1.fullName}
                          className="sidebarlink"
                          style={
                            pages === item1.fullName ? { color: "#fa8e37" } : {}
                          }
                        >
                          {item1.firstname?.toUpperCase()}{" "}
                          {item1.lastname?.toUpperCase()}
                          <br />
                        </Link>
                        :
                        <Link
                          to={item1.fullName}
                          className="sidebarlink"
                          style={
                            pages === item1.fullName ? { color: "#fa8e37" } : {}
                          }
                        >
                          {item1.firstname?.toUpperCase()}{" "}
                          {item1.lastname?.toUpperCase()}
                          <br />
                        </Link>
                      }
                    </div>
                  ))}
                  <br />
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
          <br />
        </div>
      )}
    </div>
  );
}

export default Sidebar;

import { useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const TabMotion = ({ motions }) => {
  const history = useHistory();
  const { search } = useParams();
  const { pages } = useParams();
  const [carrouselImage, setCarrouselImage] = useState();
  const [carrouselModal, setCarrouselModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);

  const handleOpenModal = (motion) => {
    console.log(motion);
    setCarrouselImage(motion);
    if (motion.motionVideoUrl) {
      setVideoModal(true);
    }
    if (motion.motionImageUrl) {
      setCarrouselModal(true);
    }
    history.push(`/${pages}/${search}?id=${motion._id}`);
  };

  const handleCloseModal = () => {
    setCarrouselModal(false);
    history.push(`/${pages}/${search}`);
  };

  const getSrc = (motion) => {
    let id = "";
    if (motion && motion.motionVideoUrl) {
      id =
        motion.motionVideoUrl.videoID.split("/")[
          motion.motionVideoUrl.videoID.split("/").length - 1
        ];
    }
    return (
      motion.motionImageUrl ||
      "https://img.youtube.com/vi/" + id + "/hqdefault.jpg"
    );
  };

  return (
    <div className="personalWorksMainContainer">
      {motions.map((motion, ind) => {
        return (
          <div
            key={ind}
            className="personalWorkContainer"
            style={{ backgroundImage: `url(${getSrc(motion)})` }}
            onClick={() => handleOpenModal(motion)}
          >
            {motion.motionVideoUrl && (
              <button className="playVideoBio">&#x2023;</button>
            )}
          </div>
        );
      })}
      {carrouselModal && (
        <div className="modalTutorial">
          <div
            className="containerModal2"
            style={{ backgroundColor: "transparent", boxShadow: "none" }}
          >
            <div className="modalCloseBtn">
              <button onClick={handleCloseModal}>×</button>
            </div>
            <div
              style={{
                maxHeight: "90vh",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <img
                src={getSrc(carrouselImage)}
                alt="secondaryImage"
                style={{ maxHeight: "90vh", maxWidth: "90%" }}
              />
            </div>
            <h4
              className="pwh4"
              style={{
                fontWeight: "300",
                fontSize: "30px",
                textAlign: "center",
                marginTop: "30px",
              }}
            >
              {carrouselImage.motionTitle}
            </h4>
          </div>
        </div>
      )}
      {videoModal ? (
        <>
          <div className="modalTutorial">
            <div className="containerModal2 containerVideoModal">
              <div className="modalCloseBtn">
                <button onClick={() => setVideoModal(false)}>×</button>
              </div>
              <iframe
                title="Personal Work Video"
                className="iframeBio"
                src={carrouselImage.motionVideoUrl.videoID}
              ></iframe>
              <h4
                className="pwh4"
                style={{
                  fontWeight: "300",
                  fontSize: "30px",
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                {carrouselImage.motionTitle.toUpperCase()}
              </h4>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TabMotion;

import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { artistKeyword } from '../../redux/artistImageKeywordDataSlice';
import loading from '../../assets/loading.gif';
import DUMMY from '../../assets/1695387962634--sub_image_0.jpg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Categories(props) {

  const history = useHistory();
  const [tempArtist, setTempArtist] = useState([]);
  const [filterCond, setFilterCond] = useState(true);
  const [filterHighlighted,setFilterHighlighted]= useState(null);
  const dispatch = useDispatch();
  const { artistImageKeywordDataSlice } = useSelector((state) => state);

  const filterChange = (filter) => {

    if (filter === "A-Z") {
      let temp = []
      let data = [...artistImageKeywordDataSlice.artistKeywordImages]
      
      setFilterCond(false)

      temp = data.sort((a, b) => a.keyword.normalize().localeCompare(b.keyword.normalize()));
      setFilterHighlighted(2)
      setTempArtist(temp)
    }
    else {
      setFilterHighlighted(1)
      setFilterCond(true)
    }

  }

  useEffect(() => {
    dispatch(artistKeyword({type:2}));
    localStorage.setItem("Category","");
  }, []);

  return (<>
    <div class="sortingcont right pt-0 mt-0">
      <div className="d-flex right-filter-button">
          <button class={filterHighlighted === 1 ? "filter-button sort-active w-inline-block  mt-0" : (filterHighlighted === 2)? "filter-button w-inline-block  mt-0" : "filter-button sort-active w-inline-block  mt-0"} style={{ marginLeft: "0px" }} onClick={()=>filterChange("Default")}>
            <div >FEATURED</div>
          </button>
          <button class={filterHighlighted === 2 ? "filter-button sort-active mt-0 me-0" : "filter-button mt-0 me-0"} onClick={()=>filterChange("A-Z")}>
            <div >ALPHABETICAL A-Z</div>
          </button>
      </div>
    </div>
    <div className="_2cols2_">
      {props.children}
      <div
        id="w-node-_6f42e407-456f-5b2f-82e4-417072db3669-84f2d081"
        className="divisionscolumn"
      >
        <div>
          {JSON.parse(localStorage.getItem("routePaths")) && (
            <div className={"d-flex"} style={{ marginBottom: "10px" }}>
              {JSON.parse(localStorage.getItem("routePaths")).map((obj, ind) => (
                  <p className={ JSON.parse(localStorage.getItem("routePaths")).length === ind + 1? "breadCrumbs": "breadCrumbsActive"}
                      onClick={ JSON.parse(localStorage.getItem("routePaths")).length ===ind + 1? () => {}: () => history.push(obj.link)}
                      style={ind === 0 ? {} : { marginLeft: "5px" }}>
                    {obj.val}{" "}
                    {JSON.parse(localStorage.getItem("routePaths")).length === ind + 1 ? null: ">"}
                  </p>
                )
              )}
            </div>
          )}
          {
            JSON.parse(localStorage.getItem("routePaths")) && 
            <h2 className="newsh2 h">{(JSON.parse(localStorage.getItem("routePaths"))[Number(JSON.parse(localStorage.getItem("routePaths")).length)-1].val).toUpperCase()}</h2>
          }
        </div>
        <div
          id="w-node-_429c632c-0632-16be-f5b5-f2b7200da64a-84f2d081"
          className="divisioncontainer"
        >
          {artistImageKeywordDataSlice.loading ? (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <img
                className="mb-2"
                alt="loading"
                src={loading}
              />
            </div> 
          ) : artistImageKeywordDataSlice.artistKeywordImages !== undefined ? (
            filterCond ? (
              <div
              id="w-node-f734ee66-0b58-4c14-e08b-49ceded015c9-84f2d081"
              className="detail_card3 "
              // style={{ paddingTop: "10px" }}
            >
              {artistImageKeywordDataSlice.artistKeywordImages.map(
                (item, key) => (
                    <>
                      <Link
                        key={key}
                        id="w-node-f734ee66-0b58-4c14-e08b-49ceded015ca-84f2d081"
                        to={item.keyword.includes("/") ?  "/categories/"+item.keyword.replace(/\//g, '_') : "/categories/"+item.keyword.replace(/\s/g, '_')}
                        className="artistcard w-inline-block"
                        onClick={()=>{localStorage.setItem("Category",item.keyword === '3D Rendering' ? "CGI" : item.keyword.charAt(0).toUpperCase() + item.keyword.slice(1) )}}
                      >
                        {/* <div className="detail_card4_h" style={{ position: "relative", overflow: "hidden" }}> */}
                        <img
                          src={String(
                            item.imagePath === "Dummy" ? DUMMY : item.imagePath
                          )}
                          loading="lazy"
                          alt=""
                          className="image"
                          // style={{ width:"100%", height:"100%"}}

                        />
                        <div className="artistnamediv">
                            <div className="artistnametext-v3">
                            {
                                item.keyword === '3D Rendering' ? "CGI" 
                              :
                                item.keyword.toUpperCase()
                              } 
                            </div>
                          </div>
                      {/* <p className="card_img_text2 pt-2">
                      
                            {item1.artistId.firstname}{" "}{item1.artistId.lastname}                                    
                      </p>   </div>  */}
                      </Link>
                    </>
                )
              )}
              </div>

            ) : (
              <div
              id="w-node-f734ee66-0b58-4c14-e08b-49ceded015c9-84f2d081"
              className="detail_card3 "
              // style={{ paddingTop: "10px" }}
            >
              {tempArtist.map((item, key) => (
                    <>
                      <Link
                        key={key}
                        id="w-node-f734ee66-0b58-4c14-e08b-49ceded015ca-84f2d081"
                        to={item.keyword.includes("/") ?  "/categories/"+item.keyword.replace(/\//g, '_') : "/categories/"+item.keyword.replace(/\s/g, '_')}
                        className="artistcard w-inline-block"
                        onClick={()=>{localStorage.setItem("Category",item.keyword === '3D Rendering' ? "3D Rendering" : item.keyword.charAt(0).toUpperCase() + item.keyword.slice(1) )}}
                      >
                        {/* <div className="detail_card4_h" style={{ position: "relative", overflow: "hidden" }}> */}
                        <img
                          src={String(
                            item.imagePath === "Dummy" ? DUMMY : item.imagePath
                          )}
                          loading="lazy"
                          alt=""
                          className="image"
                          // style={{ width:"100%", height:"100%"}}
                        />
                        <div className="artistnamediv">
                            <div className="artistnametext-v3">
                            {
                                item.keyword === '3D Rendering' ? "CGI" 
                              :
                                item.keyword.toUpperCase()
                              } 
                            </div>
                          </div>
                      {/* <p className="card_img_text2 pt-2">
                      
                            {item1.artistId.firstname}{" "}{item1.artistId.lastname}                                    
                      </p>   </div>  */}
                      </Link>
                    </>
              ))}</div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div></>
  );
}

export default Categories

import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { decodeToken } from "react-jwt";
import {
  SliderShow,
  SliderItems,
  FullScreenSliderItem,
} from "../../../components/slider/slider";
import loading from "../../../assets/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  artistImageKidDetailedSliceData,
  getSingleClient,
} from "../../../AxiosFunctions/Axiosfunctionality";
import {
  addCart,
  saveCartItemMessageKey,
  getAnEstimateHandler,
} from "../../../redux/addToCart";
import MyPopup from "../../../components/myPopup/myPopup";
import TabPublishedWorks from "./tabPublishedWorks";
import TabProperties from "./tabProperties";
import TabMotion from "./tabMotion";
import TabAwardPress from "./tabAwardPress";
import TabPersonalWorks from "./tabPersonalWorks";

function SearchByArtist(props) {
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [fullscreen, setFullscreen] = useState({ screen: false, route: null });
  const [fullScreenData, setFullScreenData] = useState({
    screen: false,
    route: null,
  });
  const { pages } = useParams();
  const { AddToCart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data1, setData1] = useState(null);
  const [dataViewed, setDataViewed] = useState({});
  const [similarData, setSimilarData] = useState({});
  const [artistImages, setArtistImages] = useState(8);
  const [sliderIndex, setSliderIndex] = useState(null);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [isPopupShowWithCheckbox, setIsPopupShowWithCheckbox] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [, setSliderTriggerred] = useState(false);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [imageIndexDisplayed, setImageIndexDisplayed] = useState(true);
  const [seeFullPorfolio, setSeeFullPorfolio] = useState(false);
  const [fullPorfolio, setFullPorfolio] = useState([]);
  const [searchPorfolio, setSeachPorfolio] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("Portfolio");
  const [tabsInArtistPortfolio, setTabsInArtistPortfolio] = useState([
    "Portfolio",
    "Bio",
    "Shannon Portfolio",
  ]);
  const [videoModal, setVideoModal] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("authorization"));
  const [clientDetails, setClientDetails] = useState();
  const { search } = useParams();
  const searchValue = search || "portfolio";

  const fullscreenCondRef = useRef({
    full: false,
    imageInd: 0,
  });
  const myStateRef = useRef(0);
  const queryParams = new URLSearchParams(history.location.search);
  const imageIndex = queryParams.get("image");
  const fullscreenCond = queryParams.get("fullscreen");
  const image = queryParams.get("image");
  const id = queryParams.get("id");

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve the token from localStorage

      if (token) {
        // Decode the token
        const details = decodeToken(token);

        if (details && details._id) {
          try {
            // Fetch the artist details
            const response = await getSingleClient({ _id: details._id });
            if (response === "NO CLIENT FOUND") {
              setClientDetails(null);
            } else {
              setClientDetails(response);
            }
          } catch (error) {
            // Handle any errors, such as network issues or decoding problems
            setClientDetails(null);
            console.error("Error fetching artist details:", error);
          }
        }
      } else {
        setClientDetails(null);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedToken = localStorage.getItem("authorization");
      if (storedToken !== token) {
        setToken(storedToken);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [token]);

  useEffect(() => {
    let newTabsInPortfolio = ["Portfolio", "Bio"];
    if (data1 && data1[pages]?.publishedWorks?.length !== 0) {
      newTabsInPortfolio.push("Published Works");
    }
    if (data1 && data1[pages]?.personalWorks?.length !== 0) {
      newTabsInPortfolio.push("Personal Works");
    }
    if (
      data1 &&
      data1[pages]?.properties.filter((property) => property.status === "1")
        ?.length !== 0
    ) {
      newTabsInPortfolio.push("Properties");
    }
    if (data1 && data1[pages]?.motions.length !== 0) {
      newTabsInPortfolio.push("Motion");
    }
    if (data1 && data1[pages]?.awardPress.length !== 0) {
      newTabsInPortfolio.push("Awards & Press");
    }
    newTabsInPortfolio.push("Shannon Portfolio");
    const removedDuplicateNewTabsinPortfolio = [...new Set(newTabsInPortfolio)];
    setTabsInArtistPortfolio(removedDuplicateNewTabsinPortfolio);
  }, [data1]);

  const handleFilterChange = (filter) => {
    const urlFriendlyFilter = filter.replace(/\s+/g, "").toLowerCase();
    history.push(`/${pages}/${urlFriendlyFilter}?image=${image}`);
  };

  const getVideoID = (url) => {
    const id = url.split("/")[url.split("/").length - 1];
    return id;
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  const changeIndex = (index) => {
    console.log(index);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    let imageInd;

    if (data1) {
      let routeTo = "";
      if (data1[pages].slideList.length < parseInt(image, 10)) {
        imageInd = data1[pages].slideList.length - 1;
        if (id) {
          routeTo = `/${pages}/${searchValue}?image=${imageInd}&id=${id}`;
        } else {
          routeTo = `/${pages}/${searchValue}?image=${imageInd}`;
        }
        history.replace(routeTo);
      } else {
        imageInd = parseInt(image, 10);
        if (id) {
          routeTo = `/${pages}/${searchValue}?image=0&id=${id}`;
        } else {
          routeTo = `/${pages}/${searchValue}?image=0`;
        }
        history.replace(routeTo);
      }
      let tempObj = { ...fullScreenData };
      tempObj.screen = fullscreenCond === "true" ? true : false;
      tempObj.route = data1[pages].slideList[imageInd];
      setFullscreen(tempObj);
      fullscreenCondRef.current.full = tempObj.screen;
      fullscreenCondRef.current.imageInd = parseInt(image, 10);
    }

    myStateRef.current = imageInd;
    if (imageInd) {
      setSliderIndex(parseInt(imageInd, 10));
    } else {
      setSliderIndex(0);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", function () {});
    };
  }, [data1]);

  useEffect(() => {
    if (!fullscreenCond) {
      if (!imageIndex) {
        history.push("?image=0");
      } else {
        history.push("?image=" + imageIndex);
      }
    }

    // return () => {
    //   localStorage.setItem("Category","none")
    // };
  }, [fullscreenCond]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (history.action === "POP") {
        if (fullscreenCondRef.current.full) {
          let temp = { ...fullscreen };

          temp.resposive = windowSize.innerWidth < 479 ? true : false;
          temp.screen = false;
          fullscreenCondRef.current.full = false;
          fullscreenCondRef.current.imageInd = parseInt(imageIndex, 10);
          setFullscreen(temp);
        } else {
          history.goBack();
        }
      }
    });

    // Return a cleanup function to remove the listener when the component unmounts.
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (!imageIndex) {
      history.push(pages + "?image=0");
    }
    // return () => {
    //   localStorage.setItem("Category","none")
    // };
  }, []);

  const addToCartArtist = (id, firstname, getAnEstimate = false) => {
    dispatch(addCart({ key: id, data: { id: id, Name: firstname } }));
    if (getAnEstimate) {
      dispatch(getAnEstimateHandler());
    }
  };

  const portfolioUpdate = async (categoryKeyword) => {
    setIsLoading(true);
    const tempData = await artistImageKidDetailedSliceData({
      fullName: pages,
      category: categoryKeyword,
    });

    const route = [
      { val: "Home", link: "./" },
      { val: "Categories", link: "./categories" },
      { val: categoryKeyword, link: "./categories/" + categoryKeyword },
      {
        val: fullPorfolio[pages].firstname + " " + fullPorfolio[pages].lastname,
        artistExist: true,
      },
    ];
    localStorage.setItem("routePaths", JSON.stringify(route));
    localStorage.setItem("Category", categoryKeyword);
    history.replace(`/${pages}/portfolio?image=0`);
    props.setCurrentCategory(categoryKeyword);
    setData1(tempData.activeArtist);
    setSeachPorfolio(tempData.activeArtist);
    setDisplayedImages(tempData.activeArtist[pages].subListData);
    setIsLoading(false);
  };

  const dataLocalArtist = (key, _id, firstname, bio, listData, subListData) => {
    let tempData = localStorage.getItem("artistViewedKid_V4");

    tempData = JSON.parse(tempData);
    if (tempData === null) {
      tempData = {};
      tempData[key] = {
        id: _id,
        title: firstname,
        detail: bio,
        slideList: listData,
      };
      localStorage.setItem("artistViewedKid_V4", JSON.stringify(tempData));
    } else {
      tempData[key] = {
        id: _id,
        title: firstname,
        detail: bio,
        slideList: listData,
        subListData: subListData,
      };

      let tempDataOnlySix = {};

      // Convert the object into an array of key-value pairs
      const entries = Object.entries(tempData);
      // Reverse the array
      const reversedEntries = entries.reverse();

      // Loop over the reversed array
      reversedEntries.forEach(([key, value], index) => {
        tempDataOnlySix[key] = value;
      });

      localStorage.setItem(
        "artistViewedKid_V4",
        JSON.stringify(tempDataOnlySix)
      );
    }
  };

  const getUserData = async () => {
    setIsLoading(true);
    let localPrevCate = localStorage.getItem("Category") || "none";
    if (props.currentDivision !== "") {
      localPrevCate =
        props.currentDivision === "Middle Grade"
          ? "Middle Grade Cover"
          : props.currentDivision;
    }

    let tempData = await artistImageKidDetailedSliceData({
      fullName: pages,
      category: localPrevCate,
    });
    let fullTempData = await artistImageKidDetailedSliceData({
      fullName: pages,
      category: "none",
    });

    if (localStorage.getItem("routePaths")) {
      let route = JSON.parse(localStorage.getItem("routePaths"));
      if (!route.find((obj) => obj.artistExist === true)) {
        route.push({
          val:
            tempData.activeArtist[pages].firstname +
            " " +
            tempData.activeArtist[pages].lastname,
          artistExist: true,
        });
        localStorage.setItem("routePaths", JSON.stringify(route));
      }
    }

    dataLocalArtist(
      tempData.activeArtist[pages].fullName,
      tempData.activeArtist[pages].id,
      tempData.activeArtist[pages].lastname +
        " " +
        tempData.activeArtist[pages].firstname,
      tempData.activeArtist[pages].detail,
      tempData.activeArtist[pages].slideList,
      tempData.activeArtist[pages].subListData
    );

    let tempImage = [];
    tempImage.push(tempData.activeArtist[pages].subListData[0]);

    setFullPorfolio(fullTempData.activeArtist);
    setSeachPorfolio(tempData.activeArtist);
    setDisplayedImages(tempImage);
    setSimilarData(tempData.similarArtist);
    setData1(tempData.activeArtist);
  };

  useEffect(() => {
    let currentSelectedSlider = document.getElementById(
      "firstSlider" + imageIndex
    );
    var prev = document.getElementsByClassName("sliderPrevArrow")[0];
    var next = document.getElementsByClassName("sliderNextArrow")[0];
    console.log(prev);
    if (currentSelectedSlider) {
      currentSelectedSlider.style.boxShadow = "0 2px 10px #141c2a";
      if (prev) {
        prev.addEventListener("click", (e) => {
          if (myStateRef.current === 0) {
            setSliderIndexHandler(
              data1[pages].pictureTitle.length - 1,
              myStateRef.current,
              true
            );
          } else {
            setSliderIndexHandler(
              myStateRef.current - 1,
              myStateRef.current,
              true
            );
          }
        });
      }

      if (next) {
        next.addEventListener("click", (e) => {
          if (myStateRef.current !== data1[pages].pictureTitle.length - 1) {
            setSliderIndexHandler(
              myStateRef.current + 1,
              myStateRef.current,
              true
            );
          } else {
            setSliderIndexHandler(
              0,
              data1[pages].pictureTitle.length - 1,
              true
            );
          }
        });
      }
    }
  }, [data1, imageIndexDisplayed, pages, search]);

  const setSliderIndexHandler = (
    keys,
    oldValue = null,
    clickedSliderButton = false
  ) => {
    if (clickedSliderButton) {
      let previousSelectedSlider = document.getElementById(
        "firstSlider" + oldValue
      );
      let currentSelectedSlider = document.getElementById("firstSlider" + keys);

      currentSelectedSlider.style.boxShadow = "0 2px 10px #141c2a";
      previousSelectedSlider.style.boxShadow = "";
      myStateRef.current = keys;
      setSliderIndex(keys);
    } else {
      let previousSelectedSlider = document.getElementById(
        sliderIndex ? "firstSlider" + sliderIndex : "firstSlider0"
      );
      let currentSelectedSlider = document.getElementById("firstSlider" + keys);

      currentSelectedSlider.style.boxShadow = "0 2px 10px #141c2a";
      previousSelectedSlider.style.boxShadow = "";
      myStateRef.current = keys;
      setSliderIndex(keys);
    }
    history.push("?image=" + keys);
  };

  useEffect(() => {
    setSliderTriggerred(false);
    setData1(null);

    function getLocalStorage() {
      if (localStorage.getItem("artistViewedKid_V4") !== null) {
        setDataViewed(JSON.parse(localStorage.getItem("artistViewedKid_V4")));
      }
    }
    handleWindowResize();
    getLocalStorage();
    getUserData();

    setIsLoading(false);
  }, [pages]);

  const setFullScreenHandler = (route, key) => {
    let temp = { ...fullscreen };

    if (!temp.screen) {
      temp.route = route;
      temp.key = key;
    }

    temp.resposive = windowSize.innerWidth < 479 ? true : false;
    temp.screen = !temp.screen;
    fullscreenCondRef.current.full = temp.screen;
    fullscreenCondRef.current.imageInd = parseInt(imageIndex, 10);
    setFullscreen(temp);
    setFullScreenData(data1[pages]);
    if (temp.screen) {
      history.push("?image=" + imageIndex + "&fullscreen=true");
    } else {
      setImageIndexDisplayed(!imageIndexDisplayed);
      history.push("?image=" + imageIndex);
    }
  };

  const saveCartMessage = (msg) => {
    dispatch(saveCartItemMessageKey({ messageShow: msg }));
  };

  const addToCartArtistHandler = (id, title, getAnEstimate = false) => {
    let key = Object.keys(AddToCart.cartInfo).find((element) => element === id);
    if (key === undefined) {
      if (AddToCart.cartInfo.messageShow) {
        setMsg(
          "You have added " +
            title +
            " to your list, to view your list visit Contact/My List Page."
        );
        setIsPopupShow(true);
      }
      addToCartArtist(id, title, getAnEstimate);
    } else {
      setMsg(
        "You have already added " +
          title +
          " to your list, to view your list visit Contact/My List Page."
      );
      setIsPopupShow(true);
      setIsPopupShowWithCheckbox(false);
    }
  };

  const onImageLoad = (index) => {
    if (
      index + 1 === displayedImages.length &&
      data1[pages].subListData.length !== index + 1
    ) {
      let tempImage = [...displayedImages];
      tempImage.push(data1[pages].subListData[index + 1]);
      setDisplayedImages(tempImage);
    }

    if (displayedImages.length === imageIndex + 1) {
      setImageIndexDisplayed(!imageIndexDisplayed);
    }
  };

  if (fullscreen.screen) {
    return (
      <FullScreenSliderItem
        onClick={setFullScreenHandler}
        currentData={fullScreenData}
        fullscreen={fullscreen}
      />
    );
  }

  if (isLoading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <img className="mb-3" alt="loading" src={loading} />
      </div>
    );
  }

  const handleCheckBox = (e) => {
    setIsLoading(true);

    // Update the state based on the previous state
    setSeeFullPorfolio((previous) => {
      const stateCheckbox = !previous;

      console.log(fullPorfolio);

      // Now use stateCheckbox in your conditionals
      if (stateCheckbox) {
        setData1(fullPorfolio);
        setDisplayedImages(fullPorfolio[pages].subListData);
      } else {
        setData1(searchPorfolio);
        setDisplayedImages(searchPorfolio[pages].subListData);
      }

      // Return the new state
      return stateCheckbox;
    });

    setImageIndexDisplayed((prev) => !prev);
    setIsLoading(false);
  };

  return (
    <div>
      {data1 !== null ? (
        <div
          className={"d-flex"}
          style={windowSize.innerWidth < 479 ? {} : { justifyContent: "end" }}
        >
          <div
            className="d-flex"
            style={
              windowSize.innerWidth < 479
                ? {}
                : { justifyContent: "space-between" }
            }
          >
            <Link
              to="/contact"
              className={
                windowSize.innerWidth < 479
                  ? " allArtistsButtons mb-4   "
                  : "talentbutton mr-3"
              }
              onClick={() =>
                addToCartArtistHandler(
                  data1[pages].id,
                  data1[pages].title,
                  true
                )
              }
            >
              GET ESTIMATE
            </Link>
            <Link
              data-w-id="e04f643e-f302-16e2-74ee-4bc7e85391d8"
              to="#"
              className={
                windowSize.innerWidth < 479
                  ? " allArtistsButtons mb-4   "
                  : "talentbutton mr-3"
              }
              onClick={() =>
                addToCartArtistHandler(data1[pages].id, data1[pages].title)
              }
              style={{ marginRight: "0px" }}
            >
              ADD TO MY LIST
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className="row m-0 pt-0"
        style={{
          maxWidth: "100%",
          justifyContent: "space-around",
          margin: "0px",
        }}
      >
        {data1 !== null && data1[pages] ? (
          <>
            <div className="pl-2 left_content">{props.children}</div>
            <div className="row mid_full_content">
              <h2 className="h2talent">{data1[pages].title}</h2>
              <div
                className="allArtistsButtonsContainer"
                style={{ width: "100%", marginBottom: "40px" }}
              >
                {/* , 'Published Works', 'Awards', 'Press',"Kid's Portfolio",'Personal Work' */}
                {tabsInArtistPortfolio.map((filter, i) => {
                  if ((i = 0)) {
                    return (
                      <button
                        key={filter}
                        className={`allArtistsButtons  ${
                          searchValue ===
                          filter.replace(/\s+/g, "").toLowerCase()
                            ? "selectedTalentButton"
                            : ""
                        }`}
                        style={{ paddingLeft: "0px" }}
                        onClick={() => handleFilterChange(filter)}
                      >
                        {filter}
                      </button>
                    );
                  } else if (filter === "Shannon Portfolio") {
                    return (
                      <a
                        href={
                          "https://www.shannonassociates.com/" +
                          data1[pages].fullName
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button key={filter} className="allArtistsButtons">
                          {filter}
                        </button>
                      </a>
                    );
                  } else {
                    return (
                      <button
                        key={filter}
                        className={`allArtistsButtons  ${
                          searchValue ===
                          filter.replace(/\s+/g, "").toLowerCase()
                            ? "selectedTalentButton"
                            : ""
                        }`}
                        onClick={() => handleFilterChange(filter)}
                      >
                        {filter}
                      </button>
                    );
                  }
                })}
              </div>
              {searchValue === "portfolio" ? (
                <>
                  {/* LEFT CONTENT */}
                  <div className="mid_content">
                    {JSON.parse(localStorage.getItem("routePaths")) ? (
                      <div
                        className={"d-flex"}
                        style={{ marginBottom: "10px", justifyContent:"space-between" }}
                      >
                        <div
                          className={"d-flex"}
                          style={{ marginBottom: "10px" }}
                        >
                          {JSON.parse(localStorage.getItem("routePaths")).map(
                            (obj, ind) => (
                              <p
                                className={
                                  JSON.parse(localStorage.getItem("routePaths"))
                                    .length ===
                                  ind + 1
                                    ? "breadCrumbs"
                                    : "breadCrumbsActive"
                                }
                                onClick={
                                  JSON.parse(localStorage.getItem("routePaths"))
                                    .length ===
                                  ind + 1
                                    ? () => {}
                                    : () => history.push(obj.link)
                                }
                                style={ind === 0 ? {} : { marginLeft: "5px" }}
                              >
                                {obj.val}{" "}
                                {JSON.parse(localStorage.getItem("routePaths"))
                                  .length ===
                                ind + 1
                                  ? null
                                  : ">"}
                              </p>
                            )
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="linkToKS"
                            style={{
                              paddingRight: "10px",
                              color: "#818181",
                              fontWeight: "300",
                              alignSelf: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            See full portfolio
                          </p>
                          <label className="switchToggle">
                            <input type="checkbox" onChange={handleCheckBox} />
                            <span className="sliderToggle roundToggle"></span>
                          </label>
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={"d-flex"}
                      style={{
                        justifyContent: "space-between",
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={
                          localStorage.getItem("routePaths")
                            ? { display: "block" }
                            : { display: "flex" }
                        }
                      ></div>
                    </div>

                    {windowSize.innerWidth < 479 ? (
                      <div className="tabs-menu w-tab-menu">
                        <div
                          onClick={() => setTab(0)}
                          className={
                            "tabs py-2 w-inline-block w-tab-link flex_center " +
                            (tab === 0
                              ? "bg-white text-black flex_center"
                              : null)
                          }
                        >
                          <div style={{ fontWeight: "600" }}>PORTFOLIO</div>
                        </div>
                        <div
                          onClick={() => setTab(1)}
                          className={
                            "tabs py-2 w-inline-block w-tab-link " +
                            (tab === 1 ? "bg-white  text-black" : null)
                          }
                        >
                          <div style={{ fontWeight: "600" }}>
                            SIMILAR ILLUSTRATORS
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tab === 0 ? (
                      windowSize.innerWidth < 479 ? (
                        <div>
                          <div className="imagecont" style={{ marginTop: 10 }}>
                            {data1[pages].subListData.map((item, keys) =>
                              keys < artistImages ? (
                                <div
                                  key={keys}
                                  className="talentthumbslide resp"
                                  onClick={() => setFullScreenHandler(item)}
                                >
                                  <img
                                    src={item}
                                    loading="lazy"
                                    alt=""
                                    className="image"
                                  />
                                </div>
                              ) : null
                            )}
                          </div>
                          {artistImages < data1[pages].subListData.length && (
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                textAlign: "center",
                                margin: "10px",
                                marginTop: 25,
                              }}
                            >
                              <div
                                style={{ fontSize: "16px" }}
                                className="talentbuttonArtistSearch "
                                onClick={() =>
                                  setArtistImages(artistImages + 8)
                                }
                              >
                                See More
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="detail_card w-inline-block ">
                          {displayedImages.map((item, keys) => (
                            <div
                              id={"firstSlider" + keys}
                              className="detail_card5_h"
                              style={
                                windowSize.innerWidth <= 991
                                  ? { overflow: "hidden", height: "8vh" }
                                  : { overflow: "hidden", height: "14.5vh" }
                              }
                              onClick={() => {
                                setSliderIndexHandler(keys);
                              }}
                            >
                              <img
                                srcSet={item}
                                className="w-100 h-100"
                                alt="Artist Work"
                                style={{ objectFit: "cover" }}
                                loading="lazy"
                                fetchpriority={"high"}
                                // onLoad={() => onImageLoad(keys)}
                                onLoadCapture={() => onImageLoad(keys)}
                              ></img>
                            </div>
                          ))}
                          {data1[pages].subListData[displayedImages.length] ? (
                            <div
                              style={
                                windowSize.innerWidth <= 991
                                  ? { overflow: "hidden", height: "8vh" }
                                  : { overflow: "hidden", height: "14.5vh" }
                              }
                            >
                              <img
                                className="mb-3"
                                alt="loading"
                                src={loading}
                              />
                            </div>
                          ) : null}
                        </div>
                      )
                    ) : null}
                  </div>
                  {/* RIGHT CONTENT */}
                  <div className="right_content">
                    <div className="rightside">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        {fullscreen.screen ? (
                          <FullScreenSliderItem
                            onClick={setFullScreenHandler}
                            currentData={data1[pages]}
                            fullscreen={fullscreen}
                          />
                        ) : (
                          <>
                            {data1[pages].slideList.length > 0 &&
                            data1[pages].slideList.length === 1 ? (
                              <div>
                                {data1[pages].slideList.map((item, key) => (
                                  <SliderItems
                                    key={key}
                                    index={key}
                                    sliderIndex={sliderIndex}
                                    src={item}
                                    data1={data1}
                                    search={pages}
                                    windowSize={windowSize}
                                    onClick={setFullScreenHandler}
                                    addToCartArtistHandler={
                                      addToCartArtistHandler
                                    }
                                  />
                                ))}
                              </div>
                            ) : (
                              <SliderShow
                                changeIndex={changeIndex}
                                sliderIndex={sliderIndex}
                              >
                                {data1[pages].slideList.map((item, key) => (
                                  <SliderItems
                                    key={key}
                                    index={key}
                                    sliderIndex={sliderIndex}
                                    src={item}
                                    data1={data1}
                                    search={pages}
                                    windowSize={windowSize}
                                    onClick={setFullScreenHandler}
                                    addToCartArtistHandler={
                                      addToCartArtistHandler
                                    }
                                  />
                                ))}
                              </SliderShow>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : searchValue === "bio" ? (
                <>
                  {/* Leftside content */}
                  <div className="pl-2 mid_content mid_content_bio">
                    {data1[pages].artistImage ? (
                      <img
                        className="bioMainImage"
                        alt="Artist"
                        src={data1[pages].artistImage}
                      ></img>
                    ) : (
                      <img
                        className="bioMainImage"
                        alt="Artist"
                        src="/assets/images/bioDefaultImage.png"
                      ></img>
                    )}
                    {data1[pages].photoCredit &&
                      data1[pages].photoCredit !== "" && (
                        <div>
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "20px",
                              marginTop: "5px",
                              fontWeight: "300",
                              lineHeight: "1.5",
                              fontSize: "0.8rem",
                            }}
                          >
                            Photo Credit : {data1[pages].photoCredit}
                          </p>
                        </div>
                      )}
                    {data1[pages].bipocType !== "None" && (
                      <div>
                        <h3
                          className="homeh3"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          Bipoc
                        </h3>
                        <button
                          className="skillTag"
                          style={{ cursor: "default" }}
                        >
                          {" "}
                          {data1[pages].bipocType}{" "}
                        </button>
                      </div>
                    )}
                    <div className="pronounsContainer">
                      {data1[pages].pronouns.length !== 0 ? (
                        data1[pages].pronouns[0] !== "" ? (
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Pronouns
                          </h3>
                        ) : null
                      ) : null}
                      {data1[pages].pronouns.length !== 0
                        ? data1[pages].pronouns?.map((pronoun, i) => {
                            if (pronoun !== "") {
                              return (
                                <button
                                  key={i}
                                  className="skillTag"
                                  style={{ cursor: "default", margin: "0px" }}
                                >
                                  {pronoun}
                                </button>
                              );
                            }
                            return null;
                          }) || null
                        : null}
                    </div>
                    <div className="pronounsContainer">
                      {data1[pages].socialMedia.length !== 0 ? (
                        data1[pages].socialMedia[0].socialKey !== "" ? (
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Social
                          </h3>
                        ) : null
                      ) : null}
                      {data1[pages].socialMedia.length !== 0
                        ? data1[pages].socialMedia?.map((sM, i) => {
                            if (sM.socialKey !== "") {
                              return (
                                <a
                                  key={i}
                                  href={sM.socialValue}
                                  target="_blanck"
                                  style={{ marginRight: "15px" }}
                                >
                                  <img
                                    alt="Social Media Logo"
                                    style={{ width: "40px", cursor: "pointer" }}
                                    src={
                                      "/assets/images/" +
                                      sM.socialKey +
                                      "BioImage.png"
                                    }
                                  />
                                </a>
                              );
                            }
                            return null;
                          }) || null
                        : null}
                    </div>
                    <div className="pronounsContainer">
                      {data1[pages].skills.length !== 0 ? (
                        data1[pages].skills[0] !== "" ? (
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Skills
                          </h3>
                        ) : null
                      ) : null}
                      {data1[pages].skills.length !== 0
                        ? data1[pages].skills?.map((skill, i) => {
                            if (skill !== "") {
                              return (
                                <button
                                  key={i}
                                  className="skillTag"
                                  style={{ cursor: "default" }}
                                >
                                  {skill}
                                </button>
                              );
                            }
                            return null;
                          }) || null
                        : null}
                    </div>
                    {data1[pages].videoUrl ? (
                      data1[pages].videoUrl.videoKey === "YouTube" ? (
                        <>
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Process
                          </h3>
                          <div
                            className="mb-5"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                alt="Youtube thumbnail"
                                width="auto"
                                src={
                                  "https://img.youtube.com/vi/" +
                                  getVideoID(data1[pages].videoUrl.videoID) +
                                  "/hqdefault.jpg"
                                }
                              ></img>
                              <button
                                className="playVideoBio"
                                onClick={() => setVideoModal(true)}
                              >
                                &#x2023;
                              </button>
                            </div>
                          </div>
                        </>
                      ) : data1[pages].videoUrl.videoKey === "Vimeo" ? (
                        <>
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Process
                          </h3>
                          <div
                            className="mb-5"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                alt="Vimeo thumbnail"
                                width="auto"
                                src={
                                  "https://vumbnail.com/41579/" +
                                  data1[pages].videoUrl.videoID +
                                  ".jpg"
                                }
                              ></img>
                              <button
                                className="playVideoBio"
                                onClick={() => setVideoModal(true)}
                              >
                                &#x2023;
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null
                    ) : null}
                    {videoModal ? (
                      <>
                        <div className="modalTutorial">
                          <div className="containerModal">
                            <div className="modalCloseBtn">
                              <button onClick={() => setVideoModal(false)}>
                                ×
                              </button>
                            </div>
                            <iframe
                              title="Personal Work Video"
                              className="iframeBio"
                              src={data1[pages].videoUrl.videoID}
                            ></iframe>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/* Rightside content */}
                  <div className="right_content_bio">
                    <h3
                      className="homeh3"
                      style={{ textDecoration: "none", marginBottom: "5px" }}
                    >
                      Biography
                    </h3>
                    <div
                      className="F large"
                      style={{
                        fontSize: "17px",
                        lineHeight: "2",
                      }}
                    >
                      <div
                        className="BioDetailDescriptionPortfolio"
                        dangerouslySetInnerHTML={{
                          __html: data1[pages].detail,
                        }}
                        style={{ marginBottom: "35px" }}
                      ></div>
                    </div>
                    <div className="pronounsContainer">
                      {data1[pages].website.length !== 0 ? (
                        <>
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            Website{" "}
                          </h3>
                          {data1[pages].website.map((w, i) => (
                            <a
                              key={i}
                              href={w}
                              target="_blanck"
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              <button className="keywordsTag">
                                {w} <br />
                              </button>
                            </a>
                          ))}
                        </>
                      ) : null}
                    </div>
                    <div>
                      {data1[pages].uniqueKeywords.length !== 0 ? (
                        <>
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Category Skills
                          </h3>
                          {data1[pages].uniqueKeywords.map(
                            (categoryKeyword, i) => (
                              <button
                                onClick={(e) => {
                                  portfolioUpdate(categoryKeyword);
                                }}
                                className="keywordsTag"
                                style={{ cursor: "pointer" }}
                              >
                                {categoryKeyword}
                              </button>
                            )
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : searchValue === "publishedworks" ? (
                <TabPublishedWorks
                  publishedWorks={data1[pages].publishedWorks}
                />
              ) : searchValue === "personalworks" ? (
                <TabPersonalWorks personalWorks={data1[pages].personalWorks} />
              ) : searchValue === "properties" ? (
                <TabProperties
                  access={clientDetails}
                  properties={data1[pages].properties.filter(
                    (property) => property.status === "1"
                  )}
                  artist={data1[pages].title}
                />
              ) : searchValue === "motion" ? (
                <TabMotion motions={data1[pages].motions} />
              ) : searchValue === "awards&press" ? (
                <TabAwardPress awardPress={data1[pages].awardPress} />
              ) : null}
              <div
                style={{ width: "100%", backgroundColor: "white", zIndex: "1" }}
              >
                {/* Starting of Similar Artist */}
                {windowSize.innerWidth > 479 ? (
                  <div
                    style={{
                      fontWeight: "700",
                      backgroundColor: "#ce651e",
                      fontSize: "18px",
                      color: "#fff",
                      paddingLeft: "20px",
                      marginTop: "5vh",
                      marginBottom: "3vh",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                    }}
                    className=" "
                  >
                    CLIENTS WHO VIEWED THIS ARTIST ALSO VIEWED
                  </div>
                ) : null}
                <div className="pl-2">
                  {windowSize.innerWidth < 479 ? (
                    tab === 1 ? (
                      <div>
                        <div className="imagecont" style={{ marginTop: 10 }}>
                          {Object.keys(similarData).length > 0
                            ? Object.keys(similarData).map((key, i) => (
                                <div className="talentthumbslide resp">
                                  <Link
                                    id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                                    data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                                    className="card_img3"
                                    // style={{ position: "relative" }}
                                    to={key}
                                  >
                                    <div className="detail_card6_h">
                                      <img
                                        src={String(similarData[key].mainImage)}
                                        loading="lazy"
                                        alt=""
                                        className="image"
                                        style={{ width: "100%" }}
                                      />
                                      <div className="artistnamediv">
                                        <div
                                          className="artistnametext-v3"
                                          style={{ padding: "6px 0px" }}
                                        >
                                          {similarData[key].firstname}{" "}
                                          {similarData[key].lastname}
                                        </div>
                                      </div>
                                      {/* <p className="card_img_text3 pb-3 pt-1">
                                  {similarData[key].lastname} {similarData[key].firstname}</p> */}
                                    </div>
                                  </Link>
                                </div>
                              ))
                            : "NO SIMILAR IMAGES FOUND"}
                        </div>
                        {/* <div style={{ textAlign: "center", margin: "25px 10px 10px" }}>
                      <Link
                        to="#"
                        style={{ fontSize: "16px" }}
                        className="talentbuttonArtistSearch col-3 mr-1"
                        onClick={() => setArtistSimilar(artistSimilar + 8)}
                      >
                        See More
                      </Link>
                    </div> */}
                      </div>
                    ) : null
                  ) : (
                    <div className="detail_card2 my-2">
                      {Object.keys(similarData).length > 0
                        ? Object.keys(similarData).map((key, i) => (
                            <Link
                              id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                              data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                              className="card_img3"
                              // style={{ position: "relative" }}
                              to={key}
                            >
                              <div className="detail_card6_h">
                                <img
                                  src={String(similarData[key].mainImage)}
                                  loading="lazy"
                                  alt=""
                                  className="image"
                                  style={{ width: "100%" }}
                                />
                                <div className="artistnamediv">
                                  <div
                                    className="artistnametext-v3"
                                    style={{ padding: "6px 0px" }}
                                  >
                                    {similarData[key].firstname}{" "}
                                    {similarData[key].lastname}
                                  </div>
                                </div>
                                {/* <p className="card_img_text3 pb-3 pt-1">
                              {similarData[key].lastname} {similarData[key].firstname}</p> */}
                              </div>
                            </Link>
                          ))
                        : "NO SIMILAR IMAGES FOUND"}
                    </div>
                  )}
                </div>
                {/* ENDING of Similar Artist */}

                {/* Starting of Recently Viewed */}
                {/* Starting of Recently Viewed */}

                {windowSize.innerWidth > 479 ? (
                  <div
                    style={{
                      width: "100%",
                      fontWeight: "700",
                      backgroundColor: "#ce651e",
                      fontSize: "18px",
                      color: "#fff",
                      paddingLeft: "20px",
                      marginTop: "8vh",
                      marginBottom: "3vh",
                    }}
                    className="py-2 "
                  >
                    RECENTLY VIEWED
                  </div>
                ) : null}
                {windowSize.innerWidth < 479 ? null : (
                  <div className="detail_card2 my-2">
                    {Object.keys(dataViewed).length > 0
                      ? Object.keys(dataViewed).map((key, i) =>
                          i <= 18 && dataViewed[key].subListData ? (
                            <Link
                              id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                              data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                              className="card_img3"
                              to={key}
                            >
                              <div className="detail_card6_h">
                                <img
                                  src={String(dataViewed[key].subListData[0])}
                                  loading="lazy"
                                  alt=""
                                  className="image"
                                  style={{ width: "100%" }}
                                />
                                <div className="artistnamediv">
                                  <div
                                    className="artistnametext-v3"
                                    style={{ padding: "6px 0px" }}
                                  >
                                    {dataViewed[key].title}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ) : null
                        )
                      : ""}
                  </div>
                )}

                {/* Ending of Recently Viewed */}
                {/* Ending of Recently Viewed */}
              </div>
            </div>
          </>
        ) : (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <img className="mb-3" alt="loading" src={loading} />
          </div>
        )}
      </div>

      {/* END OF ROWS AND COLUMN */}
      {/* END OF ROWS AND COLUMN */}
      {/* END OF ROWS AND COLUMN */}
      {/* END OF ROWS AND COLUMN */}
      {/* END OF ROWS AND COLUMN */}

      <div className="contactpage mt-5 pt-2">
        {isPopupShow && isPopupShowWithCheckbox ? (
          <MyPopup
            BackClose
            onClose={() => {
              saveCartMessage(!isCheckboxChecked);
              setIsPopupShow(false);
            }}
          >
            <div className="mx-5 my-4">
              <div>{msg}</div>
              <div
                class="form-check form-switch mt-2"
                style={{ display: "flex" }}
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  style={{ cursor: "pointer", accentColor: "#BC6127" }}
                  checked={isCheckboxChecked}
                  onClick={() => {
                    setIsCheckboxChecked(!isCheckboxChecked);
                  }}
                />
                <label
                  class="form-check-label"
                  for="flexSwitchCheckDefault"
                  style={{ paddingTop: "5px" }}
                >
                  Do not show this again
                </label>
              </div>
            </div>
            <div
              className="cartBadgeSearchArtist"
              onClick={() => {
                saveCartMessage(!isCheckboxChecked);
                setIsPopupShow(false);
              }}
            >
              x
            </div>
          </MyPopup>
        ) : isPopupShow && !isPopupShowWithCheckbox ? (
          <MyPopup
            BackClose
            onClose={() => {
              setIsPopupShowWithCheckbox(true);
              setIsPopupShow(false);
            }}
          >
            <div className="mx-5 my-4">
              <div>{msg}</div>
            </div>
            <div
              className="cartBadgeSearchArtist"
              onClick={() => {
                saveCartMessage(!isCheckboxChecked);
                setIsPopupShow(false);
              }}
            >
              x
            </div>
          </MyPopup>
        ) : null}
      </div>
    </div>
  );
}

export default SearchByArtist;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = window.location.origin + "/assets/images";

function Navbar(props) {
  const { AddToCart, artistDataAPI } = useSelector((state) => state);
  const [searchOption, setSearchOption] = useState();

  const localStorageAddToCart = () => {
    let addToCartArray = [];
    Object.keys(AddToCart.cartInfo).map((oneKey, i) => {
      if (
        oneKey !== "messageShow" &&
        oneKey !== "count" &&
        oneKey !== "getAnEstimate"
      ) {
        addToCartArray.push(AddToCart.cartInfo[oneKey]);
      }
    });
    console.log(addToCartArray);
    if (addToCartArray.length > 0) {
      localStorage.setItem("addToCart", JSON.stringify(addToCartArray));
    } else {
      localStorage.removeItem("addToCart");
    }
  };

  useEffect(() => {
    if (props.searchArtist === "") {
      setSearchOption();
    } else {
      let tempSearch = artistDataAPI.artistData.filter(
        (artist) =>
          artist.firstname
            .toLowerCase()
            .includes(props.searchArtist.toLowerCase()) ||
          artist.lastname
            .toLowerCase()
            .includes(props.searchArtist.toLowerCase())
      );
      setSearchOption(tempSearch);
    }
  }, [props.searchArtist]);

  const removeRoute = () => {
    localStorage.removeItem("routePaths");
    localStorage.setItem("Bipoc", "none");
    localStorage.setItem("Category", "none");
  };
  const sliderSettings = {
    dots: false,
    speed: 500,
    nextArrow: (
      <div style={{ height: "auto" }}>
        <img src={images + "/right.png"} loading="lazy" alt="" />
      </div>
    ),
    prevArrow: <div style={{ height: "auto" }}></div>,
    arrows: true,
    variableWidth: true,
    className: "notes-slider",
    swipeToSlide: true,
  };
  return (
    <div className="div-block-11 w-100">
      <div className=" div-block-5 w-100">
        <div className="mobileViewNavbar">
          <div className="sliderContainer">
            <Slider {...sliderSettings}>
              <a
                href="https://www.shannonassociates.com/bipoc"
                target="_blank"
                rel="noreferrer"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "kidshannon" ? "w--current" : "")
                }
                onClick={() => localStorageAddToCart()}
              >
                BIPOC
              </a>
              <a
                href="https://www.shannonassociates.com/graphicNovel"
                target="_blank"
                rel="noreferrer"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "kidshannon" ? "w--current" : "")
                }
                onClick={() => localStorageAddToCart()}
              >
                GRAPHIC NOVEL
              </a>
              <Link
                to="/MIDDLE-GRADE"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "MIDDLE-GRADE" ? "w--current" : "")
                }
              >
                MIDDLE GRADE
              </Link>
              <Link
                to="/PICTURE-BOOK"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "PICTURE-BOOK" ? "w--current" : "")
                }
              >
                PICTURE BOOK
              </Link>

              <Link
                to="/MULTICULTURAL"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "MULTICULTURAL" ? "w--current" : "")
                }
              >
                MULTICULTURAL
              </Link>

              <Link
                to="/BLACK-AND-WHITE-INTERIOR"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "BLACK-AND-WHITE-INTERIOR"
                    ? "w--current"
                    : "")
                }
              >
                BLACK AND WHITE INTERIOR{" "}
              </Link>
              <Link
                to="/CHARACTER-DEVELOPMENT"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "CHARACTER-DEVELOPMENT"
                    ? "w--current"
                    : "")
                }
              >
                CHARACTER DEVELOPMENT
              </Link>

              <Link
                to="/EDUCATIONAL"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "EDUCATIONAL" ? "w--current" : "")
                }
              >
                EDUCATIONAL
              </Link>
            </Slider>
          </div>
          {props.searchBar ? (
            <div className="form-block-2 search">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="form-2"
              >
                <input
                  onChange={(e) => {
                    props.updateTempArtist(e);
                  }}
                  value={props.searchArtist}
                  type="text"
                  className="searchbarhome w-input"
                  maxLength="256"
                  name="Search"
                  data-name="Search"
                  placeholder="SEARCH"
                  id="Search"
                />
                <Link to="#" className="link-block-3 w-inline-block"></Link>
              </form>
            </div>
          ) : null}
        </div>

        <div className="fullViewNavbar">
          <div className="filterhomebox v2">
            <div
              className="filterhomebox v2"
              style={{ padding: "14px 0px 0px 0px", lineHeight: "0" }}
            >
              <a
                href="https://www.shannonassociates.com/bipoc"
                target="_blank"
                rel="noreferrer"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "kidshannon" ? "w--current" : "")
                }
                onClick={() => localStorageAddToCart()}
              >
                BIPOC
              </a>
              <a
                href="https://www.shannonassociates.com/graphicNovel"
                target="_blank"
                rel="noreferrer"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "kidshannon" ? "w--current" : "")
                }
                onClick={() => localStorageAddToCart()}
              >
                GRAPHIC NOVEL
              </a>
              <Link
                to="/MIDDLE-GRADE"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "MIDDLE-GRADE" ||
                  props.currentDivision === "Middle Grade"
                    ? "w--current"
                    : "")
                }
              >
                MIDDLE GRADE
              </Link>
              <Link
                to="/PICTURE-BOOK"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "PICTURE-BOOK" ||
                  props.currentDivision === "Picture Book"
                    ? "w--current"
                    : "")
                }
              >
                PICTURE BOOK
              </Link>
              <Link
                to="/MULTICULTURAL"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "MULTICULTURAL" ||
                  props.currentDivision === "Multicultural"
                    ? "w--current"
                    : "")
                }
              >
                MULTICULTURAL
              </Link>
              <Link
                to="/BLACK-AND-WHITE-INTERIOR"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "BLACK-AND-WHITE-INTERIOR" ||
                  props.currentDivision === "Black and White Interior"
                    ? "w--current"
                    : "")
                }
              >
                BLACK AND WHITE INTERIOR
              </Link>
              <Link
                to="/CHARACTER-DEVELOPMENT"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "CHARACTER-DEVELOPMENT" ||
                  props.currentDivision === "Character Development"
                    ? "w--current"
                    : "")
                }
              >
                CHARACTER DEVELOPMENT
              </Link>
              <Link
                to="/EDUCATIONAL"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "EDUCATIONAL" ||
                  props.currentDivision === "Educational"
                    ? "w--current"
                    : "")
                }
              >
                EDUCATIONAL
              </Link>
            </div>
            {props.searchBar ? (
              <div
                className="form-block-2"
                style={{ marginLeft: "auto", zIndex: "2" }}
              >
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form-2"
                >
                  <input
                    onChange={(e) => {
                      props.updateTempArtist(e);
                    }}
                    type="text"
                    className="searchbarhome w-input"
                    maxLength="256"
                    value={props.searchArtist}
                    name="Search"
                    data-name="Search"
                    placeholder="SEARCH"
                    id="Search"
                  />
                  <Link to="#" className="link-block-3 w-inline-block"></Link>
                </form>
                {searchOption && props.aciveBtn !== undefined ? (
                  <ul className="searchListUl">
                    {searchOption.map((listE, index) => (
                      <li key={index}>
                        <a onClick={removeRoute} href={"/" + listE.fullName}>
                          {listE.firstname + " " + listE.lastname}
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllContents,
  getSingleClient,
} from "../../AxiosFunctions/Axiosfunctionality";
import { addCart } from "../../redux/addToCart";
import { decodeToken } from "react-jwt";
import { updateMessage, updateOpen } from "../../redux/message";

const images = window.location.origin + "/assets/images";
function Header(props) {
  const { AddToCart } = useSelector((state) => state);
  const [shannonContent, setShannonContent] = useState([]);
  const [clientDetails, setClientDetails] = useState();
  const [token, setToken] = useState(localStorage.getItem("authorization"));
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [navLinkClass, setNavLinkClass] = useState(
    "navlink v2 w-nav-link w-nav-button"
  );

  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if (windowSize.innerWidth > 991) {
      setNavLinkClass("navlink v2 w-nav-link ");
    } else {
      setNavLinkClass("navlink v2 w-nav-link w-nav-button ");
    }
  }, [windowSize]);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const dispatch = useDispatch();

  const getAllContent = () => {
    getAllContents({ type: "SHANNON" }).then((res) => {
      let shannon = res[0].content;
      setShannonContent(shannon);
    });
  };

  const divisionsArray = [
    "Middle Grade",
    "Picture Book",
    "Multicultural",
    "Black and White Interior",
    "Character Development",
    "Educational",
  ];

  const localStorageAddToCart = () => {
    let addToCartArray = [];
    Object.keys(AddToCart.cartInfo).map((oneKey, i) => {
      if (
        oneKey !== "messageShow" &&
        oneKey !== "count" &&
        oneKey !== "getAnEstimate"
      ) {
        addToCartArray.push(AddToCart.cartInfo[oneKey]);
      }
    });
    if (addToCartArray.length > 0) {
      localStorage.setItem("addToCart", JSON.stringify(addToCartArray));
    } else {
      localStorage.removeItem("addToCart");
    }
  };

  const addToCartArtist = (id, firstname) => {
    dispatch(addCart({ key: id, data: { id: id, Name: firstname } }));
  };

  const addToCartArtistHandler = (id, title) => {
    let key = Object.keys(AddToCart.cartInfo).find((element) => element === id);
    if (key === undefined) {
      addToCartArtist(id, title);
    }
  };

  useEffect(() => {
    getAllContent();

    let obj = JSON.parse(localStorage.getItem("addToCart"));
    if (obj !== null) {
      obj.map((val) => {
        addToCartArtistHandler(val.id, val.Name);
      });
      localStorage.removeItem("addToCart");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve the token from localStorage

      if (token) {
        // Decode the token
        const details = decodeToken(token);

        if (details && details._id) {
          try {
            // Fetch the artist details
            const response = await getSingleClient({ _id: details._id });
            if (response === "NO CLIENT FOUND") {
              setClientDetails(null);
            } else {
              setClientDetails(response);
            }
          } catch (error) {
            // Handle any errors, such as network issues or decoding problems
            setClientDetails(null);
            console.error("Error fetching artist details:", error);
          }
        }
      } else {
        setClientDetails(null);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {}, [AddToCart]);

  const logout = () => {
    localStorage.removeItem("authorization");
    dispatch(updateOpen(true));
    dispatch(updateMessage("Successfully Logged Out"));
    window.dispatchEvent(new Event("storage"));
  };
  const isMedical = ["general", "technical", "MEDICAL"];

  const bipocPagesCurrentStyle = [
    "Black",
    "Asian",
    "Latino",
    "Central Asia",
    "Indigenous",
    "AsianArtist",
    "LatinoArtist",
    "CentralAsianArtist",
    "IndigenousArtist",
  ];
  const isGraphicNovel = [
    "covers",
    "sequential",
    "inking",
    "coloring",
    "lettering",
  ];
  return (
    <div className="menu wf-section">
      <div className="containerhome f">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="container w-container d-inline pl-0 pr-0 ">
            <Link
              to="/"
              aria-current="page"
              className="brand w-nav-brand w--current"
            >
              <img
                src={images + "/FrameLogo.png"}
                loading="lazy"
                alt=""
                className="logov2"
              />
            </Link>
            <nav role="navigation" className="navmenu mobMenu w-nav-menu">
              <div>
                <a
                  href="https://www.shannonassociates.com"
                  target="_blank"
                  rel="noreferrer"
                  className={navLinkClass}
                  onClick={() => localStorageAddToCart()}
                >
                  SHANNON WEBSITE
                </a>

                <Link
                  to="/"
                  aria-current="page"
                  className={
                    navLinkClass + (!props.aciveBtn ? "w--current" : "")
                  }
                >
                  ARTISTS
                </Link>
                <Link
                  to="/divisions"
                  className={
                    navLinkClass +
                    (props.aciveBtn === "divisions" ||
                    divisionsArray.includes(props.currentDivision)
                      ? "w--current"
                      : "")
                  }
                >
                  DIVISIONS
                </Link>
                <Link
                  to="/categories"
                  className={
                    navLinkClass +
                    (props.aciveBtn === "categories" ||
                    props.currentCategory !== "none"
                      ? "w--current"
                      : "")
                  }
                >
                  CATEGORIES
                </Link>
                <Link
                  to="/about"
                  className={
                    navLinkClass +
                    (props.aciveBtn === "about" ? "w--current" : "")
                  }
                >
                  ABOUT
                </Link>
                <Link
                  to="/contact"
                  className={
                    navLinkClass +
                    (props.aciveBtn === "contact" ? "w--current" : "")
                  }
                >
                  CONTACT/ MY LIST
                  {AddToCart.cartInfo.count > 0 ? (
                    <div className="cartBadge">{AddToCart.cartInfo.count}</div>
                  ) : null}
                </Link>
                <div className="headerAdditonalItems">
                  <hr />
                  <a
                    href="https://www.shannonassociates.com/bipoc"
                    target="_blank"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "kidshannon" ? "w--current" : "")
                    }
                    onClick={() => localStorageAddToCart()}
                  >
                    BIPOC
                  </a>
                  <a
                    href="https://www.shannonassociates.com/graphicNovel"
                    target="_blank"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "kidshannon" ? "w--current" : "")
                    }
                    onClick={() => localStorageAddToCart()}
                  >
                    GRAPHIC NOVEL
                  </a>

                  <Link
                    to="/MIDDLE-GRADE"
                    className={
                      navLinkClass +
                      "  d-flex " +
                      (props.aciveBtn === "MIDDLE-GRADE" ? "w--current" : "")
                    }
                  >
                    MIDDLE GRADE
                  </Link>

                  <Link
                    to="/PICTURE-BOOK"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "PICTURE-BOOK" ? " w--current" : "")
                    }
                  >
                    PICTURE BOOK
                  </Link>

                  <Link
                    to="/MULTICULTURAL"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "MULTICULTURAL" ? "w--current" : "")
                    }
                  >
                    MULTICULTURAL
                  </Link>

                  <Link
                    to="/BLACK-AND-WHITE-INTERIOR"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "BLACK-AND-WHITE-INTERIOR"
                        ? " w--current undelinedNavbar"
                        : "")
                    }
                  >
                    BLACK AND WHITE INTERIOR{" "}
                  </Link>
                  <Link
                    to="/CHARACTER-DEVELOPMENT"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "CHARACTER-DEVELOPMENT"
                        ? "w--current"
                        : "")
                    }
                  >
                    CHARACTER DEVELOPMENT
                  </Link>

                  <Link
                    to="/EDUCATIONAL"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "EDUCATIONAL"
                        ? " w--current undelinedNavbar"
                        : "")
                    }
                  >
                    EDUCATIONAL
                  </Link>
                </div>
                {clientDetails && (
                  <button
                    className={navLinkClass}
                    onClick={logout}
                    style={{ backgroundColor: "white" }}
                  >
                    <img
                      alt="logout"
                      src="/assets/images/clientLogOut.svg"
                      className="logoutImage"
                    />
                  </button>
                )}
              </div>
              <div className="menuinfo v2">
                {shannonContent.length > 0
                  ? "INFO@SHANNONASSOCIATES.COM • 212.333.2551"
                  : "INFO@SHANNONASSOCIATES.COM • 212.333.2551"}
              </div>
            </nav>
            <div className="menu-button w-nav-button"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

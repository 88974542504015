import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import loading from "../../assets/loading.gif";
import { artistImageNavSliceData } from "../../AxiosFunctions/Axiosfunctionality";

function NavBarArtist(props) {
  const { pages } = useParams();
  const [data, setData] = useState(null);
  const [dataOriginal, setDataOriginal] = useState(null);
  const [dataLoad, setDataLoad] = useState(true);
  const [tempArtist, setTempArtist] = useState([]);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const [, setFilterCond] = useState(true);
  const [windowSize] = useState(getWindowSize());
  const history = useHistory();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const filterChange = (filter) => {
    console.log("W");
    let tempData = [...data];
    setDataOriginal([...data]);
    if (filter === "A-Z") {
      setFilterCond(false);
      setFilterHighlighted(2);
      tempData = tempData.sort((a, b) =>
        a.artistId.lastname
          .normalize()
          .localeCompare(b.artistId.lastname.normalize())
      );
    } else if (dataOriginal) {
      setFilterHighlighted(1);
      setFilterCond(true);
      tempData = [...dataOriginal];
      // tempData = dataOriginal;
    }
    setData(tempData);
  };

  useEffect(() => {
    localStorage.setItem("Category", props.currPage);
    const letter = props.currPage;
    const route = [
      { val: "Home", link: "./" },
      { val: "Divisions", link: "./divisions" },
      { val: letter, link: "./" + pages },
    ];
    localStorage.setItem("routePaths", JSON.stringify(route));
    localStorage.setItem("Bipoc", "none");
    setDataLoad(true);
    setFilterHighlighted(null);
    artistImageNavSliceData({ keyword: props.currPage, type: 2 }).then(
      (res) => {
        setData(res.filter((e) => e.artistId.status === 1));
        setDataLoad(false);
      }
    );
  }, [props.currPage, pages]);

  useEffect(() => {
    localStorage.setItem("Category", props.currPage);
  }, [pages, props.currPage]);

  return (
    <>
      <div class="sortingcont right pt-0 mt-0">
        <div className="d-flex right-filter-button">
          <button
            class={
              filterHighlighted === 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted === 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </button>
          <button
            class={
              filterHighlighted === 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </button>
        </div>
      </div>
      <div className="_2cols2_" style={{ clear: "both" }}>
        {props.children}
        <div
          id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403e-576fcec6"
          className="divisionscolumn"
        >
          <div>
            {JSON.parse(localStorage.getItem("routePaths")) ? (
              <div className={"d-flex"} style={{ marginBottom: "10px" }}>
                {JSON.parse(localStorage.getItem("routePaths")).map(
                  (obj, ind) => (
                    <p
                      className={
                        JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? "breadCrumbs"
                          : "breadCrumbsActive"
                      }
                      onClick={
                        JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? () => {}
                          : () => history.push(obj.link)
                      }
                      style={ind === 0 ? {} : { marginLeft: "5px" }}
                    >
                      {obj.val}{" "}
                      {JSON.parse(localStorage.getItem("routePaths")).length ===
                      ind + 1
                        ? null
                        : ">"}
                    </p>
                  )
                )}
              </div>
            ) : null}
            <h2 className="newsh2 h">{(JSON.parse(localStorage.getItem("routePaths"))[Number(JSON.parse(localStorage.getItem("routePaths")).length)-1].val).toUpperCase()}</h2>
          </div>
          <div
            id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403f-576fcec6"
            className="divisioncontainer"
          >
            <div
              id="w-node-_4a165d69-02be-f2c1-10f5-69fa49464043-576fcec6"
              className="_4cols-v2"
            >
              {dataLoad ? (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <img className="mb-3" alt="loading" src={loading} />
                </div>
              ) : data ? (
                data.map((val, ind) => (
                  <>
                    <Link
                      id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                      data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                      to={val.artistId.fullName}
                      className="artistcard  w-inline-block"
                    >
                      <img
                        src={String(val.mainImage[0].subImage[0].path)}
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                      {/* <p className="card_img_text2"> 
                {val.artistId.firstname} {val.artistId.lastname} </p> */}
                      {/* <div className="artistnamediv">
                  <div className="artistnametext">
                    {val.artistId.firstname} {val.artistId.lastname} 
                  </div>
                </div> */}
                      <div className="artistnamediv">
                        <div className="artistnametext-v3">
                          {val.artistId.firstname} {val.artistId.lastname}
                        </div>
                      </div>
                    </Link>
                  </>
                ))
              ) : (
                tempArtist.map((val, ind) => (
                  <>
                    <Link
                      id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                      data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                      to={val.artistId.fullName}
                      className="artistcard  w-inline-block"
                    >
                      <img
                        src={String(val.mainImage[0].subImage[0].path)}
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                      {/* <p className="card_img_text2 pt-2">
                {val.artistId.firstname} {val.artistId.lastname} </p> */}
                      {/* <div className="artistnamediv">
                  <div className="artistnametext">
                    {val.artistId.firstname} {val.artistId.lastname} 
                  </div>
                </div> */}
                      <div className="artistnamediv">
                        <div className="artistnametext-v3">
                          {val.artistId.firstname} {val.artistId.lastname}
                        </div>
                      </div>
                    </Link>
                  </>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBarArtist;
